@use '@on3/styles/base';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.block {
  @include mix.spaceY(var.$spacing-regular);

  h2 {
    @extend %h3;

    line-height: 1.2;
    padding-bottom: var.$spacing-compact;
    border-bottom: 2px solid var.$lightNeutral;
  }

  .list {
    display: grid;
    gap: var.$spacing-regular;
  }

  .links {
    @include mix.flex($justify: space-between);

    a {
      @extend %link-more;

      color: var.$on3primary;
    }
  }
}
