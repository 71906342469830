@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

@include mix.homelayout;

.metaIcon {
  height: 20px;
  fill: currentcolor;

  [data-nil] {
    fill: var.$on3primary;
  }
}

.sidebarright {
  position: relative;
}
