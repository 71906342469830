@use '@on3/styles/variables' as var;

.iconWrapper {
  width: 100px;
  height: auto;
}

.iconBackground {
  fill: var.$orange;
}

.iconMainIcon {
  width: 84px;
  height: auto;
}
