@use '@on3/styles/variables' as var;

.wrapper {
  align-items: center;
}

.title {
  width: 100%;
  font-family: var.$geist;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding-bottom: 5px;
}

.sideContainer {
  width: 100%;
}

.footer {
  padding-top: 10px;
  width: 100%;
}

.footerLink {
  font-family: var.$geist;
  line-height: 14px;
  letter-spacing: 1.15px;
}

/**  Coach Salary Items */
.itemContainer {
  display: flex;
  align-items: center;
  background-color: var.$white;
  margin: 5px 0;
  padding: 10px;
  gap: 5px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%);
}

.headShot {
  max-height: 50px;
  max-width: 50px;
  border-radius: 3px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.positionContainer {
  display: flex;
  align-items: center;
  color: var.$gray;
  font-size: 10px;
  line-height: 13px;
  gap: 3px;
}

.sportIcon {
  justify-self: center;
  align-self: flex-start;
  height: 24px;
  width: 15px;
  fill: var.$gray;

  &.rotate {
    transform: rotate(90deg);
  }
}

.nameLink {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.15px;
  gap: 5px;
}

.position {
  color: var.$gray;
  font-size: 10px;
  line-height: 13px;
}

.salaryAgeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: end;
}

.salary {
  font-weight: 600;
  line-height: 19px;
}

.age {
  font-weight: 400;
  line-height: 13px;
  font-size: 10px;
  gap: 3px;
}
