@use '@on3/styles/variables' as var;

@mixin textStyle() {
  font-family: var.$geist;
  color: var.$gray;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
}

.viewAll {
  font-family: var.$geist;
  letter-spacing: 1.15px;
  line-height: 14px;
}

.titleText {
  font-size: 16px;
  line-height: 24px;
}

// Refactor
.container {
  background-color: var.$white;
  margin-bottom: 5px;
  padding: 10px 10px 0;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%);
  min-width: 100%;
}

.list {
  width: 100%;

  .container:first-of-type {
    margin-top: 8px;
  }

  .container:last-of-type {
    margin-bottom: 8px;
  }
}

.sidebarWrapper {
  padding: 10px;
  align-items: unset;
}

.playerWrapper {
  grid-area: player;
  display: grid;
  border-bottom: 1px solid var.$lightNeutral;
  padding-bottom: 10px;
  grid-template-areas:
    'player nil'
    'status .';
}

.label {
  color: var.$black;
}

.associationContainer {
  grid-area: association;
  display: flex;
  align-items: center;

  .association {
    @include textStyle;
  }

  .sportIcon {
    justify-self: center;
    height: 15px;
    width: 24px;
    fill: var.$gray;

    &.rotate {
      transform: rotate(90deg);
    }
  }

  .classRank {
    @include textStyle;

    &.noClassRank {
      margin-left: 4px;
    }
  }
}

.playerContainer {
  grid-area: player;
  display: flex;
  column-gap: 10px;

  .playerAvatar {
    height: 50px;
    width: 50px;
    border-radius: 3px;
  }

  .playerNameWrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: var.$geist;
  }

  .playerName {
    font-family: var.$geist;
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.15px;
  }

  .playerAvatar,
  .playerName {
    &:hover {
      filter: brightness(80%);
    }
  }

  .playerDetailsContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .playerVitalsContainer {
    @include textStyle;

    display: flex;
    align-items: center;
    gap: 3px;
  }
}

.nilValueContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-area: nil;

  .nilValue {
    @include textStyle;

    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .nilValueText {
    font-size: 15px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.65px;
    color: var.$black;
    padding-top: 3px;
  }
}

.valuationUndisclosed {
  color: var.$gray;
  font-size: 11px;
  font-weight: 500;
  text-transform: lowercase;
}

.ratingWrapper {
  display: flex;
  flex-direction: column;
}

.companyContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: flex-end;

  .companyWrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .source {
    display: flex;
    align-items: center;
    font-family: var.$geist;
    line-height: 14px;
    letter-spacing: 1.15px;
    gap: 5px;

    &.justSource {
      width: 100%;
      justify-content: flex-end;
    }

    &:hover {
      filter: brightness(80%);
    }

    svg path {
      height: 11px;
      width: 14px;
    }
  }

  .date {
    display: flex;
    color: var.$gray;
    grid-area: date;
  }
}
