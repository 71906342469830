@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

// Refactor
.container {
  background-color: var.$white;
  margin-top: 20px;
  padding: 20px 20px 0;
  box-shadow: 0 1px 5px rgb(0 0 0 / 12%);

  @include mix.on3MQ {
    display: flex;
    display: grid;
    grid-template-columns: 60px 1fr 0.5fr 1.25fr 0.5fr;
    grid-template-areas: 'player player player deals deals';
    min-width: 100%;
    padding: 0;
    margin-top: 15px;
  }
}

.label {
  display: flex;
  color: var.$gray;
  min-width: fit-content;
}

.companyWrapper {
  flex-basis: 30%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: fit-content;
  text-align: center;
}

.collectiveWrapper {
  flex-basis: 30%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var.$gray;
  gap: 5px;
  width: fit-content;
  text-align: center;

  .collectiveLogo,
  .collectiveLink {
    &:hover {
      filter: brightness(90%);
    }
  }
}

.trackerWrapper {
  display: flex;
  flex-direction: column;
  grid-area: deals;
  background-color: var.$neutralDarkGray;
  margin: 0 -20px;
  padding: 20px;
  min-width: 100%;
  row-gap: 20px;

  &.fullWidth {
    .companyWrapper {
      min-width: 100%;
    }

    .collectiveWrapper {
      min-width: 100%;
    }
  }

  @include mix.on3MQ {
    display: grid;
    margin: 0;
    padding: 20px 0;
    grid-template-columns: 1.25fr 0.5fr;
    grid-template-areas:
      'partner deal'
      'details deal';

    &.fullWidth {
      .collectiveWrapper,
      .companyWrapper {
        flex-basis: 100%;
        align-items: center;
        min-width: 100%;
        align-self: center;
      }
    }

    &.fullHeight {
      grid-template-areas: 'left deal';

      .dealPartners {
        grid-area: left;
      }

      .detailsLink {
        grid-area: left;
      }
    }
  }
}

.dealPartners {
  display: flex;
  grid-area: partner;
  min-width: 100%;
  justify-content: center;

  @include mix.on3MQ {
    margin-bottom: 0;
  }
}

.median {
  flex-basis: 30%;
  display: flex;
  justify-content: center;
  align-items: center;

  .left {
    display: flex;
    align-items: center;
    min-height: 100%;
    border-right: 2px solid var.$focusBorder;

    .arrow {
      transform: rotate(180deg);
      height: 15px;
      width: 15px;
    }
  }

  .right {
    min-height: 100%;
    display: flex;
    align-items: center;

    .arrow {
      height: 15px;
      width: 15px;
    }
  }
}

.detailsLink {
  grid-area: details;
  display: flex;
  font-size: 13px;
  letter-spacing: 0.35px;
  line-height: 18px;
  max-width: fit-content;
  text-align: center;
  padding: 0 10px;
  justify-self: center;

  &:hover {
    filter: brightness(80%);
  }

  @include mix.on3MQ {
    padding-top: 15px;
    margin-bottom: 0;
  }
}

.dealWrapper {
  grid-area: deal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @include mix.on3MQ {
    flex-direction: column;
  }
}

.dealContainer {
  display: flex;
  background: var.$newGreen;
  border-radius: 5px;
  padding: 8px 15px;
  align-items: center;
  max-width: fit-content;
  justify-content: center;
  min-width: 90px;
  min-height: 37px;

  &.undisclosed {
    background: rgb(41 240 133 / 15%);
  }

  @include mix.on3MQ {
    margin: 0 20px;
  }
}

.checkmarkContainer {
  background-color: var.$neutralDark;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  height: var.$icon-small;
  width: var.$icon-small;
  margin-right: 5px;

  .checkmarkIcon {
    padding: 2px;
    fill: var.$white;
  }
}

.amount {
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: 0.15px;
}

.undisclosed {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.15px;
}

.source {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  gap: 5px;
  background-color: var.$lightNeutral;
  padding: 5px 15px;
  min-width: 90px;
  min-height: 37px;
  color: var.$gray;
  border-radius: 5px;

  &:hover {
    filter: brightness(80%);
  }

  .icon {
    fill: var.$gray;
    height: 15px;
    width: 15px;
  }
}

.date {
  display: flex;
  color: var.$gray;
  grid-area: date;
}
