@use '@on3/styles/variables' as var;

.nilResourceHeader {
  padding: 10px;
  background-color: var.$on3primary;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .nilIcon {
    padding-bottom: 8px;
    width: 100px;
    height: 25px;
  }

  .headerText {
    padding-top: 8px;
    width: 100%;
    text-align: center;
    border-top: 1px solid rgb(255 255 255 / 20%);
    color: var.$white;
  }
}

.articleList {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
}

.articleItem {
  display: flex;
  padding: 10px;
  gap: 10px;
  background-color: var.$white;

  .imageLink {
    min-height: 80px;
    min-width: 80px;
  }

  .image {
    border-radius: 5px;
    height: 80px;
    width: 80px;
  }

  .articleInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .articleCategory:hover {
      filter: brightness(85%);
    }

    .articleTitle:hover {
      filter: brightness(10%);
    }
  }
}
